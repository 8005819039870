<template>
    <div>
        <div style="margin-top:20px">
            <span>
                {{ $t("registSuccess.wel").format(this.$parent.brandConfig.display) }}.
            </span>
        </div>
    </div>
</template>

<script>

export default {
    name:'RegistSuccess',
}
</script>

<style scoped></style>
